<template>
    <div class="ecommerce-searchbar mt-1" style="width:100%" >
      <h5>Budget général 2022</h5>
<!--
  <b-table :items="items" :fields="fields" head-variant="light">
    <template #cell(show_details)="row">
      <b-link @click="row.toggleDetails()" class="h3">+</b-link>
    </template>

    <template v-slot:row-details="row">
      <b-table :items="row.item.uos" :fields="fieldsUo"  thead-class="d-none" style="width:100%">
        <template #cell(show_uo)="row1">
          <b-link @click="row1.toggleDetails()" class="h3">+</b-link>
        </template>
        <template #cell(name)="row1">
          <p>{{ row1.item.name }}</p>
        </template>
        <template v-slot:row-details="row1">
          <b-table :items="row1.item.ouvrages" :fields="fieldsOuvrage" thead-class="d-none" style="width:100%">
              <template #cell(show_ouvrage)="row2">
                <b-link @click="row2.toggleDetails()" class="h3">+</b-link>
              </template>

              <template v-slot:row-details="row2">
                <b-table :items="row2.item.repart" :fields="fieldsrepart" thead-class="d-none" style="width:100%">

                </b-table>
              </template>
          </b-table>
        </template>
      </b-table>
    </template>
  </b-table>
-->
  <div class="m-2">
    <img :src="budgetsrc" width="100%"/>
  </div>
  </div>
  
</template>
 
<script>

import {
   BRow, BCol,BTable, BCard, BButton, BFormCheckbox, BLink
} from 'bootstrap-vue'
 
export default {
  name: 'app',
  components: {BRow, BCol,BTable, BCard, BButton, BFormCheckbox,BLink},

data () {
    return {
        budgetsrc:require('@/assets/images/tableau/budget.png'),
        fields: [{ key: "show_details", label: "", thStyle: { width: "5%"}},
        { key: "plateforme", label: "Plateforme", type: "text", thStyle: { width: "55%"}, class:"h4"},
        { key: "last", label: "2021", type: "text", thStyle: { width: "10%"}, class:"h4"},
        { key: "prev", label: "Prévisionnel", type: "text", thStyle: { width: "10%"}, class:"h4"},
        { key: "real", label: "Réalisé", type: "text", thStyle: { width: "10%"}, class:"h4"},
        { key: "total", label: "Total", type: "text", thStyle: { width: "10%"}, class:"h4"},
        ],

        fieldsUo: [{ key: "show_uo", label: "", thStyle: { width: "5%"}},
        { key: "test", label: "nom", type: "text", thStyle: { width: "55%"}},
        { key: "last", label: "N - 1", type: "text", thStyle: { width: "10%"}},
        { key: "prev", label: "Prévisionnel", type: "text", thStyle: { width: "10%"}},
        { key: "real", label: "Réalisé", type: "text", thStyle: { width: "10%"}},
        { key: "total", label: "Total", type: "text", thStyle: { width: "10%"}},
        ],

        fieldsOuvrage: [{ key: "show_ouvrage", label: "", thStyle: { width: "5%"}},
        { key: "ouvrage", label: "Ouvrage", type: "text", thStyle: { width: "55%"}},
        { key: "last", label: "N - 1", type: "text", thStyle: { width: "10%"}},
        { key: "prev", label: "Prévisionnel", type: "text", thStyle: { width: "10%"}},
        { key: "real", label: "Réalisé", type: "text", thStyle: { width: "10%"}},
        { key: "total", label: "Total", type: "text", thStyle: { width: "10%"}},
        ],

        fieldsrepart: [{ key: "show_ouvrage", label: "", thStyle: { width: "5%"}},
        { key: "name", label: "Ouvrage", type: "text", thStyle: { width: "55%"}},
        { key: "last", label: "N - 1", type: "text", thStyle: { width: "10%"}},
        { key: "prev", label: "Prévisionnel", type: "text", thStyle: { width: "10%"}},
        { key: "real", label: "Réalisé", type: "text", thStyle: { width: "10%"}},
        { key: "total", label: "Total", type: "text", thStyle: { width: "10%"}},
        ],

        items: [
            { plateforme:'Roissy', last:'150 000', prev:'112 000', real:'24 000', total: '136 000', uos:[
              {test:"UO CDG-U",last:'150 001', prev:'112 000', real:'24 000', total: '136 000',
                  ouvrages:[
                    {ouvrage:"ouvrage1", last:'150 000', prev:'112 000', real:'24 000', total: '136 000',
                    repart:[
                      {name:"maintenance",last:'150 000', prev:'112 000', real:'24 000', total: '136 000'},
                      {name:"inspection",last:'150 000', prev:'112 000', real:'24 000', total: '136 000'}
                    ]},
                    {ouvrage:"ouvrage1", last:'150 000', prev:'112 000', real:'24 000', total: '136 000',
                    repart:[
                      {name:"maintenance",last:'150 000', prev:'112 000', real:'24 000', total: '136 000'},
                      {name:"inspection",last:'150 000', prev:'112 000', real:'24 000', total: '136 000'}
                    ]},
                    {ouvrage:"ouvrage1", last:'150 000', prev:'112 000', real:'24 000', total: '136 000',
                    repart:[
                      {name:"maintenance",last:'150 000', prev:'112 000', real:'24 000', total: '136 000'},
                      {name:"inspection",last:'150 000', prev:'112 000', real:'24 000', total: '136 000'}
                    ]},
                    {ouvrage:"ouvrage1", last:'150 000', prev:'112 000', real:'24 000', total: '136 000',
                    repart:[
                      {name:"maintenance",last:'150 000', prev:'112 000', real:'24 000', total: '136 000'},
                      {name:"inspection",last:'150 000', prev:'112 000', real:'24 000', total: '136 000'}
                    ]}                    
                  ]
              },
              {test:"UO CDG-R",last:'150 000', prev:'112 000', real:'24 000', total: '136 000',
                  ouvrages:[
                     {ouvrage:"ouvrage1", last:'150 000', prev:'112 000', real:'24 000', total: '136 000',
                    repart:[
                      {name:"maintenance",last:'150 000', prev:'112 000', real:'24 000', total: '136 000'},
                      {name:"inspection",last:'150 000', prev:'112 000', real:'24 000', total: '136 000'}
                    ]},
                    {ouvrage:"ouvrage1", last:'150 000', prev:'112 000', real:'24 000', total: '136 000',
                    repart:[
                      {name:"maintenance",last:'150 000', prev:'112 000', real:'24 000', total: '136 000'},
                      {name:"inspection",last:'150 000', prev:'112 000', real:'24 000', total: '136 000'}
                    ]},
                    {ouvrage:"ouvrage1", last:'150 000', prev:'112 000', real:'24 000', total: '136 000',
                    repart:[
                      {name:"maintenance",last:'150 000', prev:'112 000', real:'24 000', total: '136 000'},
                      {name:"inspection",last:'150 000', prev:'112 000', real:'24 000', total: '136 000'}
                    ]},
                    {ouvrage:"ouvrage1", last:'150 000', prev:'112 000', real:'24 000', total: '136 000',
                    repart:[
                      {name:"maintenance",last:'150 000', prev:'112 000', real:'24 000', total: '136 000'},
                      {name:"inspection",last:'150 000', prev:'112 000', real:'24 000', total: '136 000'}
                    ]}                   
                  ]},
              ],  },
            { plateforme:'Orly', last:'240 000', prev:'160 000', real:'96 000', total: '256 000' , uos:[{name:"",last:'150 000', prev:'112 000', real:'24 000', total: '136 000',ouvrages:[{ouvrage:"ouvrage1", last:'150 000', prev:'112 000', real:'24 000', total: '136 000'}]}],  },
            { plateforme:'Bourget', last:'83 000', prev:'62 000', real:'31 000', total: '93 000' , uos:[{name:"",last:'150 000', prev:'112 000', real:'24 000', total: '136 000',ouvrages:[{ouvrage:"ouvrage1", last:'150 000', prev:'112 000', real:'24 000', total: '136 000'}]}],  },
        ]

    }
  },

}
</script>

<style scoped>
tbody.collapse.in {
  display: table-row-group;
}
</style>